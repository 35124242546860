import React from 'react'
import { StaticImage }  from "gatsby-plugin-image"
import Slider from "react-slick"



const SliderPhoto = () => {
  function Img1() {
    return <StaticImage src="../images/humans-slider/s1.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      style={{
         gridArea: "1/1",

       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img2() {
    return <StaticImage src="../images/humans-slider/s2.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      style={{
         gridArea: "1/1",

       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img3() {
    return <StaticImage src="../images/humans-slider/s3.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      style={{
         gridArea: "1/1",

       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img4() {
    return <StaticImage src="../images/humans-slider/s4.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      style={{
         gridArea: "1/1",

       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img5() {
    return <StaticImage src="../images/humans-slider/s5.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      style={{
         gridArea: "1/1",

       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img6() {
    return <StaticImage src="../images/humans-slider/s6.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      style={{
         gridArea: "1/1",

       }}
      formats={["auto", "webp", "avif"]}
       />
  }
  function Img7() {
    return <StaticImage src="../images/humans-slider/s7.jpg"
      alt=""
      placeholder="blurred"
      layout="constrained"
      style={{
         gridArea: "1/1",

       }}
      formats={["auto", "webp", "avif"]}
       />
  }


  return (
    <>
    <div style={{ position: 'relative' }}>
    {/* Color mask div */}
     <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(247, 173, 182, 0.45)' }} />
     {/* Slider container */}
      <div style={{ position: 'relative', zIndex: '-1' }}>
      <Slider
        slidesToShow={6}
        swipeToSlide={true}
        focusOnSelect={false}
        arrows={false}
        dots={false}
        infinite={true}
        speed={500}
        initialSlide={10}
        slidesToScroll={1}
        autoplay={true}
        responsive= {[
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 6,
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 969,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        }
      }
    ]}
      >
        <div className="mush">
          {Img1()}
        </div>
        <div className="mush">
          {Img2()}
        </div>
        <div className="mush">
          {Img3()}
        </div>
        <div className="mush">
          {Img4()}
        </div>
        <div className="mush">
          {Img5()}
        </div>
        <div className="mush">
          {Img6()}
        </div>
        <div className="mush">
          {Img7()}
        </div>
      </Slider>
      </div>
      </div>
    </>
  );
};

export default SliderPhoto;
